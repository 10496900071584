import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { allPartnerType, Partner } from "../utils/model"

type PartenairesPageProps = {
  data: {
    Partenaires: allPartnerType
  }
}

function PartenairesPage(props: PartenairesPageProps) {
  const Partenaires = props.data?.Partenaires.nodes ? props.data?.Partenaires.nodes
      .map(l => {
        let pictoURL = null
        if (l.data.Picto && l.data.Picto.localFiles.length > 0) {
          pictoURL = l.data.Picto.localFiles[0].publicURL
        }

        // return new Lever(l.id, l.data.Name, l.data.Label?.childMarkdownRemark.html, pictoURL, l.data.Featured)
        return new Partner(
          l.id,
          l.data.Name,
          l.data.Description?.childMarkdownRemark.html,
          pictoURL,
          l.data.URL
        )
      })
    : []
  useEffect(() => {
    document.body.className = "page-Partenaires"
  }, [])

  return (
    <Layout contentClassName="container">
      <SEO
        title="Découvrez l'ensemble des partenaires de l'aventure Shift Your Job"
        description="Shift Your Job participe à un écosystème, celui de donner du sens à son travail et sensibiliser grand public et entreprises aux enjeux énergie climat: The Shift Project, La Fresque du Climat, Le REFEDD, Makesense, Mycelium et nous l'espérons bientôt beaucoup d'autres"
        gtmPageType="aide"
        gtmPageName="Partenaires"
      />
      <div className="mx-2 py-16 md:mx-auto md:max-w-2xl lg:max-w-3xl text-primary">
        <h1 className="text-3xl md:text-5xl leading-none text-primary font-black mb-8">
          Partenaires
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-4 md:gap-6">
        {Partenaires.reverse().map(def => (
          <div
            key={def.id}
            className="mb-4"
          >
            <a href={def.url} target="_blank" className=" relative block bg-white shadow-xl flex items-center justify-center" style={{height:"175px"}}>
              <img 
              className=""
            src={def.picto} alt={def.name} 
            style={{maxHeight: "50px",maxWidth: "120px",height:"auto", width:"auto"}}/>
           <h2 className=" absolute block font-sans text-primary text-sm text-center leading-4" style={{bottom:"5px"}}>{def.name}</h2>
            {/* <div dangerouslySetInnerHTML={{ __html: def.description }}></div> */}
            </a>
           
          </div>
        ))}
        </div>
      </div>
    </Layout>  )
}

export const query = graphql`
  query PartenairesQuery {
    Partenaires: allAirtable(
      filter: { table: { eq: "Partner" }
    }) {
      nodes {
        id
        data {
          Name
          Description{
            childMarkdownRemark {
              html
            }
          }
          URL
          Picto {
            localFiles {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default PartenairesPage
